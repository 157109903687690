<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <XzTagGroup :data="statusWithAll" :active="activeStatus" @handleClick="handleStatusChange">
          <span class="title">订单状态</span>
        </XzTagGroup>
        <div class="table-wrapper" :class="{'fullscreen': isFullScreen}">
          <xz-table :columns="columnsLists" :data="data" :searchAttrs="searchAttrs" :searchVal.sync="form.code"
            :toolsAttrs="toolsAttrs" :drawerVisible.sync="drawerVisible" :scroll="{ x: '100%', y: scrollHeight }"  :drawerWidth="560" :rowKey="'id'"
            @search="handleCodeSearch" @expand="handleExpand" @refresh="handleReset" @fullScreen="handleFullScreen">
            <!--
            <template #buttons>
              <a-button type="primary" icon="plus" @click="newOrder">
                新增
              </a-button>
              <a-button>导出</a-button>
            </template>-->
            <template #others>
              <a-radio-group v-model="saleOrderType" buttonStyle="solid" @change="handleRadioChange">
                <a-radio-button v-if="isJoin" :class="{ 'active-radio': saleOrderType === 'saleOrder' }"
                  value="saleOrder">
                  加盟销售
                </a-radio-button>
                <a-radio-button :class="{ 'active-radio': saleOrderType === 'purchaseOrder' }" value="purchaseOrder">
                  直营销售
                </a-radio-button>
              </a-radio-group>
            </template>
            <template #drawer>
              <div style="flex: 1; overflow: auto">
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-form-model-item label="订单编号">
                    <a-input v-model="form.code" placeholder="请输入订单编号" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="订单状态">
                    <a-select v-model="form.status" placeholder="请选择订单状态" allow-clear>
                      <a-select-option v-for="opt in statusWithAll" :key="opt.value" :value="opt.value">
                        {{ opt.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="订单金额">
                    <a-input-number v-model="form.minOrderTotalAmount" placeholder="最小金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                    ~
                    <a-input-number v-model="form.maxOrderTotalAmount" placeholder="最大金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                  </a-form-model-item>
                  <a-form-model-item label="收款金额">
                    <a-input-number v-model="form.minSellerAmount" placeholder="最小金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                    ~
                    <a-input-number v-model="form.maxSellerAmount" placeholder="最大金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                  </a-form-model-item>
                  <a-form-model-item label="合同编号" allow-clear>
                    <a-input v-model="form.contractCode" placeholder="请输入合同编号" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="最终用户">
                    <a-input v-model="form.finalCustomerName" placeholder="请输入最终用户" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="创建人">
                    <a-input v-model="form.createUserName" placeholder="请输入创建人" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="创建时间">
                    <a-range-picker v-model="form.createDateRange"  valueFormat="x" :placeholder="['开始时间', '结束时间']" style="width: 100%;" show-time
                      allow-clear>
                    </a-range-picker>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div style="text-align: right; height: 68px; line-height: 68px">
                <a-button @click="handleReset">
                  重置
                </a-button>
                <a-button type="primary" style="margin-left: 10px;" @click="handleConfirmSearch">
                  确定
                </a-button>
              </div>
            </template>
            <template #custom>
              <el-popover
                placement="left"
                width="300"
                v-model="showAttribute"
                trigger="manual">
                <div class="top"><span>字段设置</span><time @click="showAttribute=false">&times;</time></div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists&&lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox v-model="item.isShow" :disabled="item.isDisabled" style="margin-top:6px;"></el-checkbox><div class="labelContainer">{{item.fieldName}}</div>
                      <i :class="{'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular}" style="font-size: 12px;margin-top:10px;margin-left:12px;" @click="handleClickLock(item, index)"></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">
                      锁定下列项目到此固定
                    </li>
                  </template>
                </draggable>
                <h3 style="border-top: 1px solid #DFE2E8;font-size:14px;height:40px;line-height:40px;margin-bottom:0;">不固定</h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox v-model="item.isShow" :disabled="item.isDisabled" style="margin-top:6px;"></el-checkbox><div class="labelContainer">{{item.fieldName}}</div>
                    <i :class="{'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular}" style="font-size: 12px;margin-top:10px;margin-left:12px;" @click="handleClickLock(item, index)"></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button slot="reference" @click='handleSort' style="width:32px;height:32px;margin-left:8px;padding: 9px;"><img :src="require('@/assets/ic_setting.svg')" style='width:14px;height:14px;'/></el-button>
              </el-popover>
            </template>
            <span slot="code" slot-scope="text, record">
              <a class="code" v-if="actionPermissionMap['order:list:detail']" @click="handleView(record)">{{ text }}</a>
              <span v-else>{{ text }}</span>
            </span>
            <span slot="status" slot-scope="text, record" :class="`status-${record.status}`">
              {{ mapStausName(record.status) }}
            </span>
            <span :slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <xz-table :columns="childColumns[saleOrderType]" :data="record.goodsList" :scroll="{ x: '100%' }"
                :rowKey="'id'"></xz-table>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-popconfirm v-if="record.status === SALE_CONTRACT_STATUS_DRAFT" placement="leftTop" ok-text="确认"
                cancel-text="取消" v-auth="'order:list:cancel'" @confirm="handleCancel(record)">
                <template slot="title">
                  确认取消？
                </template>
                <a>取消</a>
              </a-popconfirm>
              <a-divider v-if="record.status === SALE_CONTRACT_STATUS_DRAFT" type="vertical"
                v-auth="'order:list:update'" />
              <a v-if="record.status === SALE_CONTRACT_STATUS_DRAFT" v-auth="'order:list:update'"
                @click="handleEdit(record)">编辑</a>
              <a-divider v-if="record.status === SALE_CONTRACT_STATUS_DRAFT" type="vertical"
                v-auth="'order:list:delete'" />
              <a-popconfirm v-if="record.status === SALE_CONTRACT_STATUS_DRAFT" placement="leftTop" ok-text="确认"
                cancel-text="取消" v-auth="'order:list:delete'" @confirm="handleDelete(record)">
                <template slot="title">
                  确认删除？
                </template>
                <a style="color:#F56C6C;">删除</a>
              </a-popconfirm>
              <!--collectionStatus： 2 已收款 -->
              <a v-if="record.status === SALE_CONTRACT_STATUS_IN_PROGRESS && record.collectionStatus !== 2" @click="handleCollection(record)">收款</a>
            </span>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination :current="page.pageNum" :total="total" :show-total="total => `共 ${total} 条`" show-size-changer
        show-quick-jumper :pageSize.sync="page.pageSize" @change="handleNumChange" @showSizeChange="handleSizeChange" />
    </div>
    <a-modal v-model="visible" title="销售订单收款" :width="900" :bodyStyle="{ padding: '0 12px' }" centered @ok="handleOk"
      @cancel="visible = false;">
      <br />
      <a-descriptions bordered size="small">
        <a-descriptions-item label="核算单位">
          {{ orderInfo.accountingUnit }}
        </a-descriptions-item>
        <a-descriptions-item label="客户抬头">
          {{ orderInfo.customerHead }}
        </a-descriptions-item>
        <a-descriptions-item label="待收金额">
          {{ format(orderInfo.waitReceiptAmount) }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert v-if="isAlert" style="margin-top: 8px;" :message="warningMessage" banner closable @close="isAlert = false;" />
      <a-form-model ref="collection" :model="collectingForm">
        <xz-table ref="table" :columns="collectionColumns" :data="collectingForm.collectionData"
          :scroll="{ x: '100%', y: 600 }" rowKey="id">
          <span slot="amount" slot-scope="text, record, index">
            <a-form-model-item :prop="'collectionData.' + index + '.amount'" :rules="setRule(record)" style="margin-bottom: 0;">
              <a-input-number v-model="record.amount" placeholder="请输入结算金额" :min="0" :step="0.01" style="width: 100%;"
                :precision="2" />
            </a-form-model-item>
          </span>
          <template slot="footer" slot-scope="currentPageData">
            <div>
              <b>含税总金额（元）：</b>{{ parseInt(Math.round(currentPageData.reduce((pre, cur) => pre + cur.amount, 0) * 100)) /
                100 || '' }}
            </div>
          </template>
        </xz-table>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { saleOrderList, purchaseOrderList, cancelOrder, deleteSaleAndPurchOrder, tenantBaseInfo, receiptMoneyInfo, contactClaimList, saleOrderReceive, getFiledRuleLists, addFiledRule } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '../../../components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

const SALE_CONTRACT_STATUS_ALL = '';
const SALE_CONTRACT_STATUS_DRAFT = 0; // 草稿
const SALE_CONTRACT_STATUS_IN_PROGRESS = 3; // 执行中
const SALE_CONTRACT_STATUS_FINISHED = 5; // 已完结
const SALE_CONTRACT_STATUS_CANCELED = 4; // 已取消

const STATUS = [
  {
    label: '草稿',
    value: SALE_CONTRACT_STATUS_DRAFT,
  },
  {
    label: '执行中',
    value: SALE_CONTRACT_STATUS_IN_PROGRESS,
  },
  {
    label: '已完结',
    value: SALE_CONTRACT_STATUS_FINISHED,
  },
  {
    label: '已取消',
    value: SALE_CONTRACT_STATUS_CANCELED,
  },
];

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已取消', '已完结'];

const COMMON_COLUMNS = [
  {
    title: '订单编号',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    scopedSlots: { customRender: 'code' },
    ellipsis: true,
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    scopedSlots: { customRender: 'status' },
    ellipsis: true,
  },
  {
    title: '订单金额',
    dataIndex: 'total',
    key: 'total',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmount',
    key: 'invoiceAmount',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '收款金额',
    dataIndex: 'collectionAmount',
    key: 'collectionAmount',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '合同编号',
    dataIndex: 'contractCode',
    key: 'contractCode',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '最终用户',
    dataIndex: 'finalCustomerName',
    key: 'finalCustomerName',
    ellipsis: true,
    width: 160,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '创建人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    ellipsis: true,
    width: 170,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
  {
    title: '客户抬头',
    dataIndex: 'customerHead',
    key: 'customerHead',
    width: 160,
    ellipsis: true,
  },
];

export default {
  name: 'SaleOrders',
  components: {
    xzTable,
    XzTagGroup,
    draggable,
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
    columnsLists () {
      return this.saleOrderType === 'saleOrder' ? this.columns : this.purchaseColumns;
    },
  },
  watch: {
    saleOrderType () {
      this.handleReset();
    },
  },
  data () {
    return {
      columns: [...COMMON_COLUMNS, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 160,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      }],
      purchaseColumns: [...COMMON_COLUMNS],
      expandedRowRender: '',
      data: [],
      form: {
        code: undefined,
        status: undefined,
        minOrderTotalAmount: undefined,
        maxOrderTotalAmount: undefined,
        minSellerAmount: undefined,
        maxSellerAmount: undefined,
        contractCode: undefined,
        finalCustomerName: undefined,
        createUserName: undefined,
        createDateRange: undefined,
      },
      activeStatus: '',
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入订单编号',
      },
      drawerVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      statusWithAll: [
        {
          label: '全部',
          value: SALE_CONTRACT_STATUS_ALL,
        },
        ...STATUS,
      ],
      saleOrderType: 'saleOrder',
      childColumns: {
        saleOrder: [
          {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '规格',
            dataIndex: 'specification',
            key: 'specification',
            width: 160,
            ellipsis: true,
          },
          {
            title: '型号',
            dataIndex: 'model',
            key: 'model',
            width: 160,
            ellipsis: true,
          },
          {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            width: 160,
            ellipsis: true,
          },
          {
            title: '单价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 160,
            ellipsis: true,
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? parseInt(text * 100) + '%' : '--';
            },
          },
        ],
        purchaseOrder: [
          {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '规格',
            dataIndex: 'specification',
            key: 'specification',
            width: 160,
            ellipsis: true,
          },
          {
            title: '型号',
            dataIndex: 'model',
            key: 'model',
            width: 160,
            ellipsis: true,
          },
          {
            title: '数量',
            dataIndex: 'currentBillingNum',
            key: 'currentBillingNum',
            width: 160,
            ellipsis: true,
          },
          {
            title: '单价',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: 160,
            ellipsis: true,
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? text : '--';
            },
          },
        ],
      },
      EXECUTION_STATUS_ARRAY_MAP,
      SALE_CONTRACT_STATUS_DRAFT,
      SALE_CONTRACT_STATUS_IN_PROGRESS,
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      isJoin: false,
      visible: false,
      collectionColumns: [
        {
          title: '收款单号',
          dataIndex: 'settlementCode',
          key: 'settlementCode',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收付日期',
          dataIndex: 'settlementDate',
          key: 'settlementDate',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text ? Moment(text * 1).format('YYYY-MM-DD') : '--';
          },
        },
        {
          title: '收款单金额(元)',
          dataIndex: 'settlementAmount',
          key: 'settlementAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款单余额(元)',
          dataIndex: 'claimAmount',
          key: 'claimAmount	',
          width: 160,
          ellipsis: true,
        },
        {
          title: '本次结算金额(元)',
          dataIndex: 'amount',
          key: 'amount',
          width: 160,
          scopedSlots: { customRender: 'amount' },
        },
      ],
      orderInfo: {},
      collectingForm: {
        collectionData: [],
      },
      warningMessage: '',
      isAlert: false,
      scrollHeight: 600,
      tableBody: null,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      fixedLists: [],
      checkList: [],
      lockLists: [],
      checkLockLists: [],
      attrPropLists: [],
      lockPropLists: [],
    };
  },
  methods: {
    handleRadioChange () {
      this.getRuleLists();
      this.showAttribute = false;
    },
    newOrder () {
      this.$router.push({
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
      });
    },
    mapStausName (status) {
      let name = '';

      if (this.saleOrderType === 'saleOrder') {
        name = EXECUTION_STATUS_ARRAY_MAP[status];
      } else {
        name = status === 2 ? EXECUTION_STATUS_ARRAY_MAP[status + 1] : EXECUTION_STATUS_ARRAY_MAP[status];
      }

      return name;
    },
    setPurchOrderSts (status) {
      let retSts = status;

      if (status === SALE_CONTRACT_STATUS_IN_PROGRESS) {
        retSts = 2; // 采购订单执行中状态值为2
      }

      return retSts;
    },
    format (v) {
      let ret = '--';

      if (v || v === 0) {
        ret = v.toFixed(2);
      }

      return ret;
    },
    validatePayment (rule, value, callback) {
      if ((!value && value !== 0) || (Number(value || '') > rule.claimAmount)) {
        this.warningMessage = '结算金额不能为空且结算金额不能大于收款单余额';
        this.isAlert = true;
        return callback(new Error(' '));
      }

      callback();
    },
    setRule (row) {
      return [
        { claimAmount: row.claimAmount, validator: this.validatePayment, trigger: 'blur' },
      ];
    },
    async handleCollection (record) {
      this.isAlert = false;

      let res = await receiptMoneyInfo({ id: record.id });

      this.orderInfo = {
        id: record.id,
        ...res.body,
      };

      const param = {
        contactsType: 'CUSTOMER',
        receiptPaymentTypeEnum: 'RECEIPT',
        payableId: this.orderInfo.customerId,
        payableName: this.orderInfo.customerHead,
        type: 1, // 付款认领对应采购付款接口
        accountingUnit: this.orderInfo.accountingUnit,
        accountingUnitId: this.orderInfo.accountingUnitId,
      };

      res = await contactClaimList(param);

      let waitReceiptAmount = Number(this.orderInfo.waitReceiptAmount || '');

      (res?.body || []).forEach(v => {
        v.amount = Math.min(v.claimAmount, waitReceiptAmount);

        if (v.claimAmount <= waitReceiptAmount) {
          waitReceiptAmount = waitReceiptAmount - v.claimAmount;
        } else {
          waitReceiptAmount = 0;
        }
      });

      this.collectingForm.collectionData = res?.body || [];

      this.record = record;

      this.visible = true;
    },
    handleOk () {
      this.$refs.collection.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        let summary = this.collectingForm.collectionData.reduce((curr, next) => {
          return curr + Number(next.amount || '');
        }, 0);

        if (!summary || summary > this.orderInfo.waitReceiptAmount) {
          this.isAlert = true;
          this.warningMessage = '总认领金额不能为0，也不能大于待收金额';

          return false;
        }

        const itemList = [];

        this.collectingForm.collectionData.forEach(v => {
          if (Number(v.amount || '')) {
            itemList.push({
              amount: v.amount,
              settlementCode: v.settlementCode,
              settlementId: v.id,
            });
          }
        });

        const param = {
          saleOrderId: this.orderInfo.id,
          itemList,
        };

        saleOrderReceive(param).then(() => {
          this.handleSearch();
          this.visible = false;
        });
      });
    },
    handleConfirmSearch () {
      this.page.pageNum = 1;
      this.handleSearch();
    },
    handleReset () {
      this.activeStatus = '';

      for (const key in this.form) {
        this.form[key] = key === 'status' ? '' : undefined;
      }

      this.page.pageNum = 1;
      this.page.pageSize = 10;

      this.handleSearch();
    },
    handleSizeChange (current, size) {
      this.page.pageSize = size;

      this.handleSearch();
    },
    handleNumChange (page) {
      this.page.pageNum = page;

      this.handleSearch();
    },
    setValOrUndefined (v) {
      return (v === '' || v === null) ? undefined : v;
    },
    async handleSearch () {
      const api = this.saleOrderType === 'saleOrder' ? saleOrderList : purchaseOrderList;

      let params = {};

      this.activeStatus = this.form.status;

      if (this.saleOrderType === 'saleOrder') {
        params = {
          ...this.form,
          findInvoiceAmountFlag: true,
          findCollectionAmountFlag: true,
          findOrderGoodsFlag: true,
          findGoodsFlag: true,
          createTimeStart: this.form.createDateRange?.[0],
          createTimeEnd: this.form.createDateRange?.[1],
        };
      } else if (this.saleOrderType === 'purchaseOrder') {
        params = {
          orderCode: this.form.code,
          orderSts: this.setPurchOrderSts(this.form.status),
          minOrderTotalAmount: this.form.minOrderTotalAmount,
          maxOrderTotalAmount: this.form.maxOrderTotalAmount,
          minSellerAmount: this.form.minSellerAmount,
          maxSellerAmount: this.form.maxSellerAmount,
          pcCode: this.form.contractCode,
          finalCustomerName: this.form.finalCustomerName,
          createUserName: this.form.createUserName,
          beginCreateTime: this.form.createDateRange?.[0],
          endCreateTime: this.form.createDateRange?.[1],
          findDeviceFlag: true,
          findGoodsFlag: true,
        };
      }

      for (const k in params) {
        params[k] = this.setValOrUndefined(params[k]);
      }
      this.data = [];
      const { body } = await api({
        ...params,
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
        ...this.page,
      });

      if (this.saleOrderType === 'saleOrder') {
        this.data = body?.list || [];

        // this.tableBody.style.height = this.data.length ? this.scrollHeight + 'px' : '';
      } else if (this.saleOrderType === 'purchaseOrder') {
        this.data = (body?.list || []).map(v => ({
          id: v.id,
          code: v.orderCode,
          status: v.orderSts,
          total: v.orderTotalAmount,
          invoiceAmount: v.invoiceAmount,
          collectionAmount: v.sellerAmount,
          contractCode: v.pcCode,
          finalCustomerName: v.finalCustomerName,
          createUserName: v.createUserName,
          createTime: v.createTime,
          customerHead: v.unit,
          goodsList: v.goodsList,
        }));
      }

      this.total = body?.total;

      if (this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    handleView (record) {
      this.$router.push({
        name: this.saleOrderType === 'saleOrder' ? 'SALESMANAGEMENT_BILLINGORDER_SALE_DETAIL' : 'SALESMANAGEMENT_BILLINGORDER_PURCH_DETAIL',
        query: {
          id: this.$encode(record.id),
        },
      });
    },
    async handleCancel (record) {
      await cancelOrder({ id: record.id });

      this.handleSearch();
    },
    async handleDelete (record) {
      await deleteSaleAndPurchOrder({ saleOrderId: record.id });

      this.handleSearch();
    },
    handleEdit (record) {
      this.$router.push({
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
        query: {
          id: this.$encode(record.id),
        },
      });
    },
    handleStatusChange (v) {
      this.activeStatus = v.value;
      this.form.status = v.value;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleCodeSearch (v) {
      this.form.code = v;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    async getTenantBaseInfo () {
      const { body } = await tenantBaseInfo();

      this.isJoin = body.enteringStatus * 1 === 3;

      if (!this.isJoin) {
        this.saleOrderType = 'purchaseOrder';
      }

      this.handleSearch();
    },
    handleExpand (expand, record) {
      this.$nextTick(() => {
        setTimeout(() => {
          const element = document.querySelectorAll(`[data-row-key='${record.id}-extra-row']`);

          const height = element[0].getBoundingClientRect().height;
          element[1].childNodes[0].innerHTML = `<div style="height: ${height - 19}px;"></div>`;
        });
      }, 0);
    },
    setAntTableBodyHeight () {
      const tableWrapper = document.querySelector('.xz-table-wrapper');
      const slotWrapper = document.querySelector('.slot-wrapper');
      const thead = document.querySelector('.ant-table-thead');

      this.tableBody = document.querySelector('.ant-table-body');

      this.scrollHeight = tableWrapper.offsetHeight - slotWrapper.offsetHeight - thead.offsetHeight - 100;

      this.getTenantBaseInfo();
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      let businessType = {
        'saleOrder': '3',
        'purchaseOrder': '4',
      }[this.saleOrderType];
      getFiledRuleLists({
        businessType,
      }).then(({body}) => {
        let currentLists = body || [];
        currentLists = JSON.parse(JSON.stringify(currentLists));
        currentLists = currentLists.map(i => ({
          ...i,
          isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.fieldName),
        }));
        if (currentLists && currentLists.length) {
          this.attrLists = currentLists.filter(i => !i.isRegular);
          this.lockLists = currentLists.filter(i => i.isRegular);
          this.getColumnsLists(currentLists);
        } else {
          let columnLists = [];
          if (this.saleOrderType === 'saleOrder') {
            columnLists = [...COMMON_COLUMNS, {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              fixed: 'right',
              width: 150,
              scopedSlots: { customRender: 'action' },
            }];
            this.columns = columnLists;
          } else if (this.saleOrderType === 'purchaseOrder') {
            columnLists = COMMON_COLUMNS;
            this.purchaseColumns = columnLists;
          }
          // 这里需要根据 columnLists 获取需要处理的数据的值
          let lockLists = columnLists.filter(i => i.fixed && i.title !== '操作').map((i, index)=>({
            fieldKey: i.key,
            fieldName: i.title,
            businessType,
            isRegular: false,
            isShow: true,
            isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.title),
            sort: index + 1,
          }));
          let attrLists = columnLists.filter((i) => !i.fixed).map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType,
            isRegular: false,
            isShow: true,
            isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.title),
            sort: lockLists.length + index + 1,
          }));

          this.attrLists = attrLists;
          this.lockLists = lockLists;
        }
        let columnLists = [];
        if (this.saleOrderType === 'saleOrder') {
          columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' },
          }];
          this.columns = columnLists;
        } else if (this.saleOrderType === 'purchaseOrder') {
          columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS];
          this.purchaseColumns = columnLists;
        }
        // 这里需要拼接字段属性
        this.attrPropLists = JSON.parse(JSON.stringify(this.attrLists));
        this.lockPropLists = JSON.parse(JSON.stringify(this.lockLists));
      });
    },
    handleFullScreen (flag) {
      this.isFullScreen = flag;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let businessType = businessType = {
        'saleOrder': '3',
        'purchaseOrder': '4',
      }[this.saleOrderType];
      let columnLists = JSON.parse(JSON.stringify(COMMON_COLUMNS));
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType,
        isRegular: false,
        isShow: true,
        sort: index + 1,
        isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.title),
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
    },
    handleSave () {
      let lists = [
        ...this.lockLists,
        ...this.attrLists,
      ].map((i, index)=>({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        // this.handleSearch();这里需要根据结果 调整展示
        this.getRuleLists(lists);
        this.showAttribute = false;
      });
    },
    // 这里需要处理table
    getColumnsLists (lists) {
      let showIdLists = lists.filter(i => i.isShow).map(i => i.fieldKey);
      let fixedIdLists = lists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);

      let currentLists = COMMON_COLUMNS.filter(i => showIdLists.includes(i.dataIndex)).map(i => ({
        ...i,
        fixed: fixedIdLists.includes(i.dataIndex) ? 'left' : '',
      }));
      let tempLists = [];
      for (let i = 0; i < showIdLists.length; i++) {
        const info = currentLists.find(j => j.key === showIdLists[i]);
        tempLists.push(info);
      }
      let columnLists = tempLists;
      if (this.saleOrderType === 'saleOrder') {
        columnLists = [...columnLists, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        }];
        this.$nextTick(() => {
          this.columns = columnLists;
        });
      } else if (this.saleOrderType === 'purchaseOrder') {
        this.$nextTick(() => {
          this.purchaseColumns = columnLists;
        });
      }
    },
  },
  created () {
    this.getRuleLists();
  },
  mounted () {
    this.expandedRowRender = 'expandedRowRender';
    this.getTenantBaseInfo();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 16px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-right: 2em;
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #F2F4F7;

          &.active-radio {
            border-color: #E2F3FE;
            background-color: #E2F3FE;
            color: #237FFA;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .code {
          cursor: pointer;
          color: #237FFA;
        }

        .status-0,
        .status-4 {
          @include tag_round();
          color: #1F2733;
          background-color: #F2F4F7;
        }

        .status-1,
        .status-2,
        .status-3 {
          @include tag_round();
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .status-5 {
          @include tag_round();
          background-color: #E5FEEB;
          color: #14CA64;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 10px;
  z-index: 100;
  background: #FFFFFF;
}
.text-right{
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}
.top{
  display: flex;
  padding: 6px 0;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #DFE2E8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1F2733 100%;
  letter-spacing: 0px;
  opacity: 1;
  span{
    flex: 1;
    height: 24px;
    line-height: 24px;
  }
  time{
    cursor: pointer;
  }
}
.list-item{
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;
  .el-checkbox__label{
    height: 32px;
    line-height: 32px;
  }
  .labelContainer{
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}
.noFixedItem{
  font-family: PingFang SC;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #FFFFFF;
}
</style>

